<div class="container-fluid">

  <div class="row mt-10">
    <div class="col-md-6 offset-md-3 text-center">
      <h3>Welcome to <br> <span style="color: #F64E60;">gary club</span> </h3>
      <p>The community for creators who are ready to level up and take their game to the next level.

        We're not here to sugarcoat anything. The truth is, becoming a successful creator takes grit, determination, and
        a whole
        lot of hard work. But we're here to help you make it happen.</p>
    </div>
  </div>


  <div class="row align-items-center mt-7 mt-md-0">
    <div class="col-md-5 offset-md-1">
      <h4 class="my-4">
        OUR COMMUNITY IS POWERED
        BY <span style="color: #F64E60;">$gary</span>
      </h4>
      <p>A social token that rewards you for building your brand and engaging with our community. But it's not just
        about the
        money. We're all about helping you become the best version of yourself
        as a creator.</p>
      <h4 class="my-4">WE KNOW THAT IT’S NOT EASY</h4>
      <p>That's why we're here to push you, to challenge you, and to help you break through your own limitations. We're
        not about
        coddling you or telling you what you want to hear. We're about giving you the tough love you need to succeed.
      </p>
    </div>
    <div class="col-md-6">
      <div class="img text-center">
        <img src="assets/imgs/welcome1.png" alt="" class="img-fluid" srcset="">
      </div>
    </div>
  </div>



  <div class="row align-items-center">
    <div class="col-md-6 d-none d-lg-block">
      <div class="img text-center">
        <img src="assets/imgs/ball.png" alt="" class="img-fluid" srcset="">
      </div>
    </div>
    <div class="col-md-5">
      <h4 class="my-4">
        AND YOU WON’T BE DOING IT ALONE
      </h4>
      <p>Our community is filled with creators who are all in the same boat, who are all pushing
        themselves to be their best. We're here to support each other, to lift each other up, and to celebrate each
        other's
        wins.</p>
      <h4 class="my-4">SO IF YOU ARE READY TO STEP UP
        YOUR GAME</h4>
      <p>If you're ready to push yourself to your limits, and if you're ready to be part of a community of creators who
        are all
        about taking action, then come on in and join us. We're ready to help you unleash your full potential and become
        the
        creator you were meant to be.
      </p>

      <a href="https://t.me/garyclub" routerLinkActive="active" class="btn btn-custom">Get Involved</a>
    </div>

    <div class="col-md-6 d-none d-md-block d-lg-none">
      <div class="img text-center">
        <img src="assets/imgs/ball.png" alt="" class="img-fluid" srcset="">
      </div>
    </div>

  </div>
</div>
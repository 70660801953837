import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HdWalletAdapterModule } from '@heavy-duty/wallet-adapter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from '@full-fledged/alerts';
import { LoadingBarModule } from '@ngx-loading-bar/core';
// import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing';
import { TokenListsComponent } from './token-lists/token-lists.component';
import { LoaderComponent, TokenDetailsComponent } from './token-lists/token-details/token-details.component';
import { SwapComponent, NgbdModalContentJupiter } from './swap/swap.component';
import { PrivateSwapComponent } from './private-swap/private-swap.component';
import { GaryComponent } from './gary/gary.component';
import { NgMarqueeModule } from 'ng-marquee';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { authInterceptorProviders } from './_services/http.Interceptor';
// import { HomeComponent } from './home/home.component';
import { ToastService, AngularToastifyModule } from 'angular-toastify';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { DateAsAgoPipe } from './shared/date.pipe';
import { CreatorSwapComponent } from './creator-swap/creator-swap.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { GetInvolvedComponent } from './get-involved/get-involved.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { PowComponent } from './token-lists/token-details/pow/pow.component';
import { DatePipe } from '@angular/common';
import { MarqueeComponent } from './leaderboard/marquee/marquee.component';
import { GiraffeTowerComponent } from './giraffe-tower/giraffe-tower.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EventCalendarComponent, LoaderModalComponent } from './event-calendar/event-calendar.component';
import { EventDetailsComponent, loadModalComponent } from './event-calendar/event-details/event-details.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Location } from '@angular/common';
import { RsvpComponent } from './event-calendar/rsvp/rsvp.component';
import { CreatorDashboardComponent } from './creator-dashboard/creator-dashboard.component';
import { TowerComponent } from './tower/tower.component';
import { TowerMembersComponent } from './tower-members/tower-members.component';
import { ProfileComponent } from './profile/profile.component';
import { TeamPOWComponent } from './profile/team-pow/team-pow.component';
import { CreatorLeaderboardComponent } from './profile/creator-leaderboard/creator-leaderboard.component';
import { UserTansactionsComponent } from './profile/user-tansactions/user-tansactions.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { ActivitiesComponent } from './activities/activities.component';
import { ActivityComponent } from './activities/activity/activity.component';
import { FamComponent } from './activities/fam/fam.component';
import { SupporterComponent } from './activities/supporter/supporter.component';
import { SupportingComponent } from './activities/supporting/supporting.component';
import { PostComponent } from './post/post.component';
import { CommentsComponent } from './post/comments/comments.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { RepliesComponent } from './post/replies/replies.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PostProfileComponent } from './post/post-profile/post-profile.component';
import { ProfileV2Component } from './profile-v2/profile-v2.component';
import { Pow2Component } from './profileV2/pow2/pow2.component';
import { NotificationComponent } from './notification/notification.component';
import { TimeAgoPipe } from './shared/timeAgo.pipe';
import { PopupDataService } from './_services/popup-data.service';
import { ImagePopupComponent } from './image-popup/image-popup.component';
import { TimeAsAgoPipe } from './pipe/time-as-ago.pipe';
import { PostItemComponent } from './custom/post-item/post-item.component';
import { SaleItemComponent } from './custom/sale-item/sale-item.component';
import { RewardItemComponent } from './custom/reward-item/reward-item.component';
import { GeneralItemComponent } from './custom/general-item/general-item.component';
import { OffersItemComponent } from './custom/offers-item/offers-item.component';
import { EventsItemComponent } from './custom/events-item/events-item.component';
import { PowItemComponent } from './custom/pow-item/pow-item.component';
import { SubscriptionItemComponent } from './custom/subscription-item/subscription-item.component';
import { ReferralItemComponent } from './custom/referral-item/referral-item.component';
import { CustomComponent } from './custom/custom.component';
import { MembersComponent } from './tower/members/members.component';
import { CommentComponent } from './custom/comment/comment.component';
import { GiraffeBuyItemComponent } from './custom/giraffe-buy-item/giraffe-buy-item.component';
import { SCSwapComponent } from './swap/scswap/scswap.component';
import { NewSwapComponent } from './new-swap/new-swap.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { CodeInputModule } from 'angular-code-input';
// import { CompleteregModule } from './auth/completereg/completereg.module';
import { CompleteregComponent } from './auth/completereg/overview/completereg.component';
import { ModalComponent } from './auth/completereg/modal/modal.component';
import { BuySOLComponent } from './auth/completereg/buySOL/buySOL.component';
import { PowComponents } from './pow/pow.component';
import { TokenTnxItemComponent } from './custom/token-tnx-item/token-tnx-item.component';
import { Error404Component } from './error404/error404.component';
import { AirdropItemComponent } from './custom/airdrop-item/airdrop-item.component';
import { WenAirdropComponent } from './wen-airdrop/wen-airdrop.component';
import { JupAirdropComponent } from './jup-airdrop/jup-airdrop.component';
import { NumberWithCommasPipe } from './jup-airdrop/number.pipe';
import { ProfilleSuspendedComponent } from './profille-suspended/profille-suspended.component';
import { GaryLockedComponent } from './gary-locked/gary-locked.component';
import { AllMembersComponent } from './members/members.component';
import { SclockComponent } from './gary-locked/sclock/sclock.component';
import { ExternallockComponent } from './gary-locked/externallock/externallock.component';
import { RewardsComponent } from './rewards/rewards.component';
import { RewardsCustodialComponent } from './rewards-custodial/rewards-custodial.component';
@NgModule({
  declarations: [
    AppComponent,
    TimeAgoPipe,
    NgbdModalContentJupiter,
    TokenListsComponent,
    TokenDetailsComponent,
    PrivateSwapComponent,
    GaryComponent,
    SwapComponent,
    TowerComponent,
    TowerMembersComponent,
    DateAsAgoPipe,
    CreatorSwapComponent,
    WelcomeComponent,
    GetInvolvedComponent,
    LeaderboardComponent,
    PowComponent,
    MarqueeComponent,
    CreatorDashboardComponent,
    GiraffeTowerComponent,
    EventCalendarComponent,
    EventDetailsComponent,
    RsvpComponent,
    LoaderModalComponent,
    LoaderComponent,
    loadModalComponent,
    ProfileComponent,
    TeamPOWComponent,
    CreatorLeaderboardComponent,
    UserTansactionsComponent,
    LoginComponent,
    HomeComponent,
    ActivitiesComponent,
    ActivityComponent,
    FamComponent,
    SupporterComponent,
    SupportingComponent,
    PostComponent,
    CommentsComponent,
    RepliesComponent,
    PostProfileComponent,
    NotificationComponent,
    ProfileV2Component,
    Pow2Component,
    ImagePopupComponent,
    TimeAsAgoPipe,
    PostItemComponent,
    SaleItemComponent,
    RewardItemComponent,
    GeneralItemComponent,
    OffersItemComponent,
    EventsItemComponent,
    PowItemComponent,
    SubscriptionItemComponent,
    ReferralItemComponent,
    CustomComponent,
    MembersComponent,
    CommentComponent,
    GiraffeBuyItemComponent,
    SCSwapComponent,
    NewSwapComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,

    CompleteregComponent,
    ModalComponent,
    BuySOLComponent,
    HomeComponent,
    PowComponents,
    TokenTnxItemComponent,
    Error404Component,
    AirdropItemComponent,
    WenAirdropComponent,
    JupAirdropComponent,
    NumberWithCommasPipe,
    ProfilleSuspendedComponent,
    GaryLockedComponent,
    AllMembersComponent,
    SclockComponent,
    ExternallockComponent,
    RewardsComponent,
    RewardsCustodialComponent
  ],
  imports: [
    BrowserModule,
    NgMultiSelectDropDownModule,
    NgbModule,
    HttpClientModule,
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, positionX: 'right' }),
    HdWalletAdapterModule.forRoot({ autoConnect: true }),
    LoadingBarModule,
    FormsModule,
    ReactiveFormsModule,
    OrderModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgMarqueeModule,
    CarouselModule,
    AngularToastifyModule,
    NgApexchartsModule,
    NgxPaginationModule,
    FilterPipeModule,
    LoadingBarHttpClientModule,
    PickerModule,
    InfiniteScrollModule,
    CodeInputModule,
    // CompleteregModule
    // NgxChartsModule
  ],
  providers: [ToastService, DatePipe, Location, authInterceptorProviders, PopupDataService],
  bootstrap: [AppComponent],
  exports: [DateAsAgoPipe],
})
export class AppModule { }

import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DummyJsonResponse } from '../models/models';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from 'angular-toastify';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

const API_USERS_URL = `${environment.apiUrl}/auth`;
const stake_api = `${environment.stake_api}`
const stake_key = `${environment.stake_key}`
const program = `${environment.program}`
const nonce = `${environment.nonce}`
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const api = `${environment.base_api}`
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

export type UserType = any | undefined;
@Injectable({
  providedIn: 'root',
})

export class AppServiceService {
  private authLocalStorageToken = `user`;
  private userLocalStorageToken = `userdetails`;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  private buttonClickSubject = new Subject<void>();
  verify_Otp: boolean = false;
  buttonClick$ = this.buttonClickSubject.asObservable();
  userdata: any;
  user: any;

  triggerButtonClick(param) {
    this.buttonClickSubject.next(param);
  }

  token: any;
  activityLogs: any = [];
  // famsactivityLogs: any = [];
  errorMessage: string = null;
  commenting: boolean = false;
  post: any
  comment: any = {
    comment: "",
    post_id: "",
    tea2: "",
    tea: "",
  };

  comment2: any = {
    comment: "",
  };
  comments: any = []
  showEmojiPicker: boolean = false;
  imageErrValue: string;
  imageError: boolean;
  qqq: boolean;
  maxFileSize = 3 * 1024 * 1024;
  selectedFile: ImageSnippet;
  imgFile: any;
  postDetails: any;
  test: any;
  priceRsc: any;
  garyPrice: any;
  interval: NodeJS.Timer;
  coin_symbol: any;
  currentUserSubject: BehaviorSubject<UserType>;
  currentUser$: Observable<UserType>;
  priority: string = 'default';
  priority_fee: number = 20000;
  priority_key = 'priority_fee'

  constructor(
    private http: HttpClient,
    private router: Router,
    private sanitizer: DomSanitizer,
    // private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private modal: NgbModal,
  ) {
    this.getPriority()
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoadingSubject = new BehaviorSubject<boolean>(true);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.token = this.getAuth()
    // const subscr = this.getUserByToken(this.token).subscribe(res => {
    //   // console.log(res)
    // });
    // console.log(subscr)
    setTimeout(() => {
      this.getGaryBalance()
    }, 1000);


  }

  getGaryBalance() {
    this.getGaryPrice().subscribe({
      next: (res: any) => {
        console.log('Gary Details', res);
        this.coin_symbol = res.data.coin.coin_symbol;
        this.garyPrice = res.data?.cg_price_usd
      },
      error: (err: any) => {
        console.log('Gary Details', err);
        setTimeout(() => {
          this.getGaryBalance()
        }, 2000);
      },
      complete: () => {
        // setTimeout(() => {
        //   this.getGaryBalance()
        // }, 60000);
        // this.interval = setInterval(() => this.getGaryBalance(), 60000)
      }
    });
  }


  getBalance(data: any) {
    return this.http.post('url', data, httpOptions);
  }

  getAllCoins() {
    this.isLoadingSubject.next(true)
    return this.http.get(
      environment.base_api + `/exchange/coins/` + environment.network
    ).pipe(
      finalize(() => this.isLoadingSubject.next(false)));
  }

  getJupiterCoins() {
    return this.http.get('https://cache.jup.ag/tokens');
  }

  getTBCTokens() {
    return this.http.get(environment.base_api + `/exchange/gettbctokens`);
  }

  getAllSwaps() {
    this.isLoadingSubject.next(true);
    return this.http
      .get(environment.base_api + `/exchange/getallswap`)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getAllPrivateCoins() {
    return this.http.get(
      environment.base_api + `/exchange/private/coins/` + environment.network
    );
  }

  getSwap(a: any, b: any) {
    return this.http.get(
      environment.base_api + `/exchange/getswap/${a}/${b}`
    );
  }

  getWormholeData() {
    return this.http.get(environment.base_api + `/exchange/wormholeconfig`);
  }

  getGaryPrice() {
    return this.http.get(
      environment.base_api + '/exchange/getcoininfo/$GARY/mainnet'
    );
  }

  getcoinprice(coin: any) {
    return this.http.get(
      environment.base_api + `/exchange/getcoininfo/${coin}/mainnet`
    );
  }
  getcoinprice2(coin: any) {
    return this.http.get(
      environment.base_api + `/exchange/getcoininfo2/${coin}/mainnet`
    );
  }
  getrpc() {
    return this.http.get(environment.base_api + `/exchange/getrpc`);
  }

  checkResc(data: any) {
    return this.http.get(
      environment.base_api + `/exchange/getswaprestrictions/${data}`
    );
  }

  getcoinMarket(sort_key: any, direction: any) {
    return this.http.get(
      environment.base_api +
      `/exchange/getallcoin?sort_key=${sort_key}&direction=${direction}`
    );
  }

  getTrades(coin: any, page: any, sort_key: any, direction: any) {
    return this.http.get(
      environment.base_api +
      `/exchange/gettrades/${coin}?page=${page}&sort_key=${sort_key}&direction=${direction}`
    );
  }

  getPriceCharts(coin: any, from: any, to: any) {
    return this.http.get(
      environment.base_api + `/exchange/getpricechart/${coin}/${from}/${to}`
    );
  }

  getVolumeTraded(coin: any, from: any, to: any) {
    return this.http.get(
      environment.base_api + `/exchange/getvolumetraded/${coin}/${from}/${to}`
    );
  }

  getAnalytics() {
    return this.http.get(environment.base_api + `/exchange/analytics`)
  }


  getCreatorAnalytics(page, sort, direction, search?) {
    return this.http.get(environment.base_api + `/exchange/analytics/creator-tokens-new?cursor=${page}&sort=${sort}&direction=${direction}&search=${search}`)
  }

  getallTokens(page) {
    return this.http.get(environment.base_api + `/exchange/analytics/social-tokens-new?cursor=${page}`)
  }

  getallRewards(wallet_address: any) {
    return this.http.get(environment.base_api + `/solana/fetch-rewards?wallet_address=${wallet_address}`)
  }


  searchUser(social_tag) {
    let payload = {
      "social_tag": social_tag
    }
    return this.http.post(environment.base_api + `/exchange/socialconnector-user-search`, payload)
  }
  createCreatorReferredAccount(coin, data) {
    return this.http.post(`${environment.base_api}/user/creator/create-landing-account/${coin}`, data).pipe()
  }
  getSingleCreatorLandingDetails(token: any) {
    return this.http.get(environment.base_api + `/user/creator/referral-link/${token}`)
  }
  getTrending() {
    return this.http.get(environment.base_api + `/exchange/analytics/trending`)
  }
  getCreatorTrending() {
    return this.http.get(environment.base_api + `/exchange/analytics/trending-creators`)
  }
  getSingleToken(token: any) {
    return this.http.get(environment.base_api + `/exchange/analytics/single-coin-details/${token}`)
  }
  getCoinOffers(token: any) {
    return this.http.get(environment.base_api + `/exchange/coin_offers/${token}`)
  }

  getCoinActivity(token: any) {
    return this.http.get(environment.base_api + `/exchange/analytics/get-coin-activties/${token}`)
  }

  getCoinSupporters(token: any) {
    return this.http.get(environment.base_api + `/exchange/analytics/get-coin-supporter/${token}`)
  }

  getCreatorInfo(token: any) {
    return this.http.get(environment.base_api + `/exchange/analytics/get-coin-creator-info/${token}`)
  }

  getPaymentPages(token: any) {
    return this.http.get(environment.base_api + `/exchange/analytics/get-payment-pages/${token}`)
  }


  getCoinRewards(token: any) {
    return this.http.get(environment.base_api + `/exchange/coin_reward/${token}`)
  }

  // get leaderboard for a token
  getLeaderBoardToken(token: any) {
    return this.http.get(environment.base_api + `/exchange/leaderboard/${token}`)
  }

  getNotifications() {
    return this.http.get(`${environment.base_api}/user/notifications?type=unread`)
  }

  markAsRead(id: any) {
    return this.http.get(`${environment.base_api}/user/notifications/mark-as-read?id=${id}`)
  }

  clearNotifications() {
    return this.http.get(`${environment.base_api}/user/notifications/mark-as-read`)
  }

  getActiveTowers(val: any) {
    return this.http.get(`${environment.base_api}/exchange/tower/active?search=${val}`)
  }

  getUserTowers(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/tower/social/${social_tag}`)
  }


  getTowerMembers(ref: any, page: any) {
    return this.http.get(`${environment.base_api}/exchange/tower/ref/${ref}?page=${page}`)
  }

  getPOW(token, year) {
    return this.http.get(`${environment.base_api}/exchange/proof-of-work/${token}/${year}`).pipe()
  }


  getPOWStats(token) {
    return this.http.get(`${environment.base_api}/exchange/proof-of-work-statistics/${token}`).pipe()
  }


  getBadges(token) {
    return this.http.get(`${environment.base_api}/exchange/nft-badges/${token}`).pipe()
  }

  getAllEvents(page, segment_id, start_date, end_date, coin_symbol, user_id) {
    let url = `${environment.base_api}/events/social-connector`;

    // Add page parameter if it is not null or undefined
    if (page !== null && page !== undefined) {
      url += `?page=${page}`;
    }

    // Add segment_id parameter if it is not null, undefined, or empty
    if (segment_id !== null && segment_id !== undefined && segment_id !== '') {
      if (page !== null && page !== undefined) {
        url += `&segment_id=${segment_id}`;
      } else {
        url += `?segment_id=${segment_id}`;
      }
    }

    // Add start_date parameter if it is not null, undefined, or empty
    if (start_date !== null && start_date !== undefined && start_date !== '') {
      if (page !== null && page !== undefined || segment_id !== null && segment_id !== undefined && segment_id !== '') {
        url += `&start_date=${start_date}`;
      } else {
        url += `?start_date=${start_date}`;
      }
    }

    // Add end_date parameter if it is not null, undefined, or empty
    if (end_date !== null && end_date !== undefined && end_date !== '') {
      if (page !== null && page !== undefined || segment_id !== null && segment_id !== undefined && segment_id !== '' || start_date !== null && start_date !== undefined && start_date !== '') {
        url += `&end_date=${end_date}`;
      } else {
        url += `?end_date=${end_date}`;
      }
    }

    // Add coin_symbol parameter if it is not null, undefined, or empty
    if (coin_symbol !== null && coin_symbol !== undefined && coin_symbol !== '') {
      if (page !== null && page !== undefined || segment_id !== null && segment_id !== undefined && segment_id !== '' || start_date !== null && start_date !== undefined && start_date !== '' || end_date !== null && end_date !== undefined && end_date !== '') {
        url += `&coin_symbol=${coin_symbol}`;
      } else {
        url += `?coin_symbol=${coin_symbol}`;
      }
    }

    // Add user_id parameter if it is not null, undefined, or empty
    if (user_id !== null && user_id !== undefined && user_id !== '') {
      if (page !== null && page !== undefined || segment_id !== null && segment_id !== undefined && segment_id !== '' || start_date !== null && start_date !== undefined && start_date !== '' || end_date !== null && end_date !== undefined && end_date !== '' || coin_symbol !== null && coin_symbol !== undefined && coin_symbol !== '') {
        url += `&user_id=${user_id}`;
      } else {
        url += `?user_id=${user_id}`;
      }
    }

    return this.http.get(url, httpOptions).pipe();
  }

  getCreatorEvent(page, coin_symbol, start_date, end_date, search) {
    let url = `${environment.base_api}/events/social-connector/creator-events/${coin_symbol}`;
    // Add page parameter if it is not null or undefined
    if (page !== null && page !== undefined) {
      url += `?page=${page}`;
    }
    // Add start_date parameter if it is not null, undefined, or empty
    if (start_date !== null && start_date !== undefined && start_date !== '') {
      if (page !== null && page !== undefined) {
        url += `&start_date=${start_date}`;
      } else {
        url += `?start_date=${start_date}`;
      }
    }

    // Add end_date parameter if it is not null, undefined, or empty
    if (end_date !== null && end_date !== undefined && end_date !== '') {
      if (page !== null && page !== undefined || start_date !== null && start_date !== undefined && start_date !== '') {
        url += `&end_date=${end_date}`;
      } else {
        url += `?end_date=${end_date}`;
      }
    }


    // Add search parameter if it is not null, undefined, or empty
    if (search !== null && search !== undefined && search !== '') {
      if (page !== null && page !== undefined || start_date !== null && start_date !== undefined && start_date !== '' || end_date !== null && end_date !== undefined && end_date !== '') {
        url += `&search=${search}`;
      } else {
        url += `?search=${search}`;
      }
    }
    return this.http.get(url, httpOptions).pipe()
  }

  login(data) {
    return this.http.post(`${API_USERS_URL}/login`, data)
      .pipe(
        map((user: any) => {
          console.log('user', user)
          if (user?.data?.otp_required === true) {
            this.verify_Otp = true;
            this.userdata = user;
            return user
          } else {
            const result = this.setAuthFromLocalStorage(user);
            this.getUserByToken(user.data.token);
            return user;
          }
        }),
        catchError((err) => {
          return of(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  updateProfile(profile_picture: File, name: any, bio: any) {
    const formData = new FormData();
    // formData.append()
    formData.append('profile_picture', profile_picture);
    formData.append('name', name);
    formData.append('bio', bio);
    return this.http.post(`${api}/user/update-profile`, formData);
  }

  registration(user: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.createUser(user).pipe(
      map((rs) => {
        return rs;
      }),
      switchMap(() => this.login(user)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  createUser(user: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/register`, user);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/forgot-password`, { email, })
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }


  resetPassword(data: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/reset-password`, data)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resendOTP(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this
      .resendotp(auth.data.customer.email, auth.data.token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resendotp(email: string, token: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<any>(
      `${API_USERS_URL}/otp/resend`,
      { email },
      { headers: httpHeaders }
    );
  }

  verifyEmail(otp: any): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.verifyemail(otp, auth.data.token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  getUserDetails() {
    return this.http.get<any>(`${api}/user`, httpOptions).pipe(catchError((err) => {
      return of(undefined);
    }),
      finalize(() => {
        this.getUser();
        this.isLoadingSubject.next(false);
      })
    );
  }

  verifyemail(otp: any, token: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.post<any>(`${API_USERS_URL}/otp/verify`, otp, {
      headers: httpHeaders,
    });
  }

  getUserByToken(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<any>(`${environment.base_api}/user`, {
      headers: httpHeaders,
    }).pipe();
  }
  isLogedIn() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return false
    } else if (auth.data == null || auth.data.token === null) {
      return false
    }
    else return true
  }
  attendEvent(id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };


    return this.http.post(`${environment.base_api}/events/social-connector/attend/${id}`, {}).pipe()
  }
  checkStakeStatus() {
    return this.http
      .get(`${environment.base_api}/solana/stake/status`, httpOptions)
      .pipe();
  }

  makePayment(data, id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token


    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/events/social-connector/transaction/init/${id}`, data).pipe()
  }

  getSingleEvent(eventId: string) {
    return this.http.get(`${environment.base_api}/events/social-connector/${eventId}`, httpOptions).pipe()
  }


  getZoneByIp() {
    return this.http.get('https://worldtimeapi.org/api/ip').pipe()
  }


  public setAuthFromLocalStorage(auth: any): boolean {
    if (auth) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public async saveUserToStorage(data: any): Promise<boolean> {
    if (data) {
      console.log('dataaaaa', data)
      localStorage.setItem(this.userLocalStorageToken, JSON.stringify(data));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getUserFromStorage() {
    try {
      const userValue = localStorage.getItem(this.userLocalStorageToken);
      if (!userValue) {
        return undefined;
      }

      const userData = JSON.parse(userValue);
      return userData.permissions;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public async getUser() {
    try {
      const userValue = localStorage.getItem(this.userLocalStorageToken);
      if (!userValue) {
        return undefined;
      }

      const userData = JSON.parse(userValue);
      return userData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getAuth() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      const a = authData.data.token;
      return a;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  logout() {
    const returnUrl = location.pathname;
    localStorage.clear();
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.userLocalStorageToken);
    this.router.navigate(['/login'], {
      queryParams: { return: returnUrl },
    });
  }



  getCreatorProfile(social_tag) {
    return this.http.get(`${environment.base_api}/socialconnector-user/${social_tag}`).pipe()
  }

  getBasicProfile(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/stake/profile/basic_info/${social_tag}`).pipe()
  }
  getTransactions(social_tag, page) {
    return this.http.get(`${environment.base_api}/exchange/stake/all/purchases-public/${social_tag}?page=${page}`).pipe()
  }
  getActivityLog(social_tag, cursor) {
    return this.http.get(`${environment.base_api}/user/activity-log-new/${social_tag}?cursor=${cursor}`).pipe()
  }

  getSegmentActivityLog(segment_id, cursor) {
    return this.http.get(`${environment.base_api}/user/segment-activity-logs/${segment_id}?cursor=${cursor}`).pipe()
  }

  // segment-activity - logs

  getSupporterPerks(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/stake/all/perks-public/${social_tag}`).pipe()
  }

  getSupporters(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/stake/supporters-public/${social_tag}`).pipe()
  }
  getUserProf(social_tag) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/socialconnector-user-auth/${social_tag}`).pipe()
  }
  getSupporting(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/stake/supported-creators-by-profile-owner/${social_tag}`).pipe()
  }

  getSupportersFilter(social_tag, data) {
    return this.http.get(`${environment.base_api}/exchange/stake/supporters-public/${social_tag}?rank=${data}`).pipe()
  }
  getIfSupporter(social_tag) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/exchange/stake/profile/check_if_supporter/${social_tag}`).pipe()
  }
  getCreatedEvents(social_tag) {
    return this.http.get(`${environment.base_api}/events/social-connector/created-events/${social_tag}`).pipe()
  }
  getRSVPEvent(social_tag) {
    return this.http.get(`${environment.base_api}/events/social-connector/rsvp-events/${social_tag}`).pipe()
  }

  getAllEvent(social_tag) {
    return this.http.get(`${environment.base_api}/events/social-connector/all-events/${social_tag}`).pipe()
  }
  getAllSegmentEvent(segment_id) {
    return this.http.get(`${environment.base_api}/events/social-connector/segment-events/${segment_id}`).pipe()
  }
  getCommunities(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/get-communities/${social_tag}`).pipe()
  }

  getCreatorPOW(social_tag, year) {
    return this.http.get(`${environment.base_api}/exchange/socialconnector-pow/${social_tag}/${year}`).pipe()
  }
  getPOWDATA(social_tag, year) {
    return this.http.get(`${environment.base_api}/exchange/socialconnector-pow-data-new/${social_tag}/${year}`).pipe()
  }
  getCreatorPOWStats(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/socialconnector-pow-statistics/${social_tag}`).pipe()
  }


  getCreatorFeaturedMintPages(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/get-featured-mint-pages/${social_tag}`).pipe()
  }
  getCreatorFeaturedSocialPayPages(social_tag) {
    return this.http.get(`${environment.base_api}/social-pay/payment_pages/get-featured-payment-pages/${social_tag}`).pipe()
  }

  getSegmentFeaturedMintPages(segment_id) {
    return this.http.get(`${environment.base_api}/exchange/segment-featured-mint-pages/${segment_id}`).pipe()
  }
  getSegmentFeaturedSocialPayPages(segment_id) {
    return this.http.get(`${environment.base_api}/social-pay/payment_pages/segment-featured-payment-pages/${segment_id}`).pipe()
  }

  getCreatorFeaturedCollectibles(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/get-featured-collectibles/${social_tag}`).pipe()
  }

  sendNote(data) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/user/send-gary-coin`, data).pipe()
  }

  getstakeCurrencies() {
    return this.http.get(`${environment.base_api}/exchange/get-currencies`, httpOptions).pipe()
  }


  buyEstimate(data) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/exchange/stake/buy-estimate`, data, httpOp).pipe()
  }

  sellEstimate(data) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/exchange/stake/sell-estimate`, data).pipe()
  }

  buyStake(data) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/solana/stake/buy`, data,).pipe()
  }

  sellStake(data) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/solana/stake/sell`, data,).pipe()
  }


  checkPurchaseStatus(hash) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/exchange/stake/purchase-status/${hash}`,).pipe()
  }


  getStakeStats(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/stake-stats/${social_tag}`, httpOptions).pipe()
  }

  joinTelegram(social_tag) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/exchange/join-telegram/${social_tag}`,).pipe()
  }


  checkIfSupporter(social_tag) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/exchange/stake/profile/check_if_my_supporter/${social_tag}`, httpOp).pipe()
  }

  getuserTransactions(page) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/exchange/stake/purchases-transactions?page=${page}`).pipe()
  }

  getPrice(social_tag) {
    return this.http.get(`${environment.base_api}/exchange/stake/coin-price/${social_tag}`).pipe()
  }

  getCoreBalance() {
    return this.http.get(`${environment.base_api}/social-connector/portfolio/core-balances`).pipe()
  }

  allActivtyLogs(page?, filterParam?) {
    let url;
    if (filterParam) {
      if (filterParam == null) {
        url = `user/all-activity-logs?cursor=${page}`;
      } else {
        url = `user/all-activity-logs?cursor=${page}&actions=${filterParam}`;
      }
    } else {
      url = `user/all-activity-logs?cursor=${page}`;
    }
    return this.http.get(`${environment.base_api}/${url}`).pipe()
  }

  supporterActivtyLogs(page?, filterParam?) {
    let url;
    if (filterParam) {
      if (filterParam == null) {
        url = `user/supporters-activity-logs?cursor=${page}`;
      } else {
        url = `user/supporters-activity-logs?cursor=${page}&actions=${filterParam}`;
      }
    } else {
      url = `user/supporters-activity-logs?cursor=${page}`;
    }
    return this.http.get(`${environment.base_api}/${url}`).pipe()
  }

  supportingActivtyLogs(page?, filterParam?) {
    let url;
    if (filterParam) {
      if (filterParam == null) {
        url = `user/supporting-activity-logs?cursor=${page}`;
      } else {
        url = `user/supporting-activity-logs?cursor=${page}&actions=${filterParam}`;
      }
    } else {
      url = `user/supporting-activity-logs?cursor=${page}`;
    }
    return this.http.get(`${environment.base_api}/${url}`).pipe()


  }

  famActivtyLogs(page?, filterParam?) {
    let url;
    if (filterParam) {
      if (filterParam == null) {
        url = `user/fams-activity-logs?cursor=${page}`;
      } else {
        url = `user/fams-activity-logs?cursor=${page}&actions=${filterParam}`;
      }
    } else {
      url = `user/fams-activity-logs?cursor=${page}`;
    }
    return this.http.get(`${environment.base_api}/${url}`).pipe()

  }

  getUserWalletBalances() {
    return this.http.get(`${api}/social-connector/portfolio/custodial-balances-all`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  getSolBalance() {
    return this.http.get(`${api}/user/get-custodial-wallet-balance`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }
  getSolPrice() {
    return this.http.get(`${api}/solana/get-sol-price`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  weeklyCreatorRewards() {
    return this.http.get(`${api}/weekly-creator-rewards`, httpOptions).pipe()
  }

  getPostFeedsNew$(page): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get<DummyJsonResponse>(environment.base_api + `/user/medias/posts/get-feeds-auth?page=${page}`, httpOp).pipe(
      map((response) => ({
        items: response.data.data,
        page: page,
        hasMorePages: true
      }))

    )
  }

  getNotificationsNew$(page): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get<DummyJsonResponse>(environment.base_api + `/user/notifications?page=${page}`, httpOp).pipe(
      map((response) => ({
        items: response.data.data,
        page: page,
        hasMorePages: true
      }))

    )
  }

  getPostProfileFeedsNew$(social_tag, page): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get<DummyJsonResponse>(environment.base_api + `/user/medias/posts/get?social_tag=${social_tag}&page=${page}`, httpOp).pipe(
      map((response) => ({
        items: response.data.data,
        page: page,
        hasMorePages: true
      }))

    )
  }

  getPostFeeds(page) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(environment.base_api + `/user/medias/posts/get-feeds-auth?page=${page}`, httpOp)
  }

  getPostFeedsProfile(social_tag, page) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(environment.base_api + `/user/medias/posts/get?social_tag=${social_tag}&page=${page}`, httpOp)
  }

  likePost(id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/user/medias/posts/like-post/${id}`, httpOp).pipe()
  }

  dislikePost(id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/user/medias/posts/dislike-post/${id}`, httpOp).pipe()
  }

  dislikeComment(post_id, parent_id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/user/medias/posts/dislike-comment/${parent_id}?post_id=${post_id}?parent_id=${parent_id}`, httpOp).pipe()
  }
  likeComment(post_id, parent_id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/user/medias/posts/like-comment/${parent_id}?post_id=${post_id}?parent_id=${parent_id}`, httpOp).pipe()
  }

  getPostComments(id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/user/medias/posts/get-post-comments/${id}`, httpOp).pipe()
  }

  getCommentsComments(id) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/user/medias/posts/get-post-comments-replies/${id}`, httpOp).pipe()
  }

  postComment(data) {
    const formData = new FormData();
    if (data.file != undefined) {
      formData.append('file', data.file);
    }
    formData.append('comment', data.comment);
    formData.append('post_id', data.post_id);
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/user/medias/posts/comment`, formData, httpOp).pipe()
  }

  commentOnComment(data) {
    const formData = new FormData();
    if (data.file != undefined) {
      formData.append('file', data.file);
    }
    formData.append('comment', data.comment);
    formData.append('parent_id', data.parent_id);
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/user/medias/posts/comment`, formData, httpOp).pipe()
  }

  commentOnCommentUI(post_id, type = null) {
    console.log(post_id);
    let text;
    if (type == 'reply') {
      text = this.comment.comment;
    } else {
      text = this.comment.tea;
    }

    if (text != "" || this.imgFile != null || this.imgFile != "") {
      this.commenting = true;
      this.comment2.parent_id = post_id
      this.comment2.comment = text;
      if (this.selectedFile === undefined || this.selectedFile === null) {
        this.test = this.imgFile;
      } else {
        this.test = this.selectedFile.file;
      }

      const pic = this.test;
      this.comment2.file = pic;
      this.commentOnComment(this.comment2).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            this.commenting = false;
            this.comment.comment = "";
            this.comment2.comment = "";
            this.comment.tea = "";
            this.comment2.file = "";
            this.imgFile = "";
            // this.comment.post_id = "";
            this.selectedFile = null;
            this.comment.parent_id = "";
            this.comment2.parent_id = "";
            this.modal.dismissAll();
            // this.alert.success(res.message);
          }
        },
        error: (err: any) => {
          this.commenting = false;
          //console.log(err);
          // this.alert.danger(err.error.message);
          // this.modalService.dismissAll();
        },
      });
    }
  }

  createPost(data) {
    let payload;
    if (data.files && data.files.length > 0) {
      payload = {
        body: data.body,
        title: data.title,
        post_type: data.type,
        files: data.files,
        paid_post: data.paid_post.toString(),
        amount: data.amount
      }
    } else {
      payload = {
        body: data.body,
        title: data.title,
        post_type: data.type,
        paid_post: data.paid_post.toString(),
        amount: data.amount
      }
    }
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/user/medias/posts/create`, payload, httpOp).pipe()
  }
  uploadImage(data) {
    const formData = new FormData();
    if (data.file != undefined) {
      formData.append('file', data.file);
    }

    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.post(`${environment.base_api}/user/upload-single-file`, formData, httpOp).pipe()
  }
  deletePost(data) {

    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.delete(`${environment.base_api}/user/medias/posts/remove-post/${data.id}`, httpOp).pipe()
  }

  followUser(social_tag) {

    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/user/medias/posts/follow/${social_tag}`, httpOp).pipe()
  }

  deleteComment(data) {

    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.delete(`${environment.base_api}/user/medias/posts/remove-comment/${data.id}`, httpOp).pipe()
  }
  // linkify(text: string): string {
  //   const regex = /(\b(?:https?|ftp):\/\/\S+)/gi;
  //   return text.replace(regex, (url) => {
  //     // const truncatedText = url.length > 24 ? url.substring(0, 24) + '...' : url;
  //     return `<a href="${url}" target="_blank">${url}</a>`;
  //   });
  // }

  linkify(text: string): string {
    const regex = /(\b(?:https?|ftp):\/\/\S+)/gi;
    return text.replace(regex, (url) => {
      const truncatedText = url.length > 24 ? url.substring(0, 24) + '...' : url;
      return `<a href="${url}" target="_blank">${truncatedText}</a>`;
    });
  }

  sanitizeHTML(text: string): any {
    if (text != null) {
      // Process links and mentions
      const processedText = this.mentionify(this.linkify(text));

      // Truncate the text and add "Show more" link if it exceeds 200 characters
      let truncatedText = this.countInnerHTML(processedText) > 200 ? processedText.substring(0, 200) : processedText;

      // Check if the truncated text contains an invalid <a> tag
      if (this.containsInvalidATag(truncatedText)) {
        // Remove the improperly closed <a> tags from the truncated text
        truncatedText = truncatedText.replace(/<a\b(?![^>]*\bhref=['"]https?:\/\/)([^>]*?)<\/a>(?<!<a)/gi, (match, capturedContent) => {
          // Check if captured content is a valid URL

          return capturedContent
        });
      }
      if (this.countInnerHTML(processedText) > 200) {
        truncatedText += '... <br/><br/> <p class="custom">Show more</p>';
      }



      // Split the text into lines
      const lines = truncatedText.split(/<br>|[\r\n]+/g);

      // If there are more than 5 lines, add ellipsis and "Show more" link
      if (lines.length > 5) {
        // Update truncatedText to contain the first 5 lines
        truncatedText = lines.slice(0, 5).join('<br><br>');
        truncatedText += '... <br/><br/> <p class="custom">Show more</p>';
      }



      return this.sanitizer.bypassSecurityTrustHtml((truncatedText.replace(/(?:\r\n|\r|\n)/g, '<br>')));
    }
  }


  containsInvalidATag(text: string): boolean {
    const anchorRegex = /<a\b[^>]*>.*?<\/a>/gi;
    const matches = text.matchAll(anchorRegex);

    for (const match of matches) {
      const openingTag = /<a\b[^>]*>/i.exec(match[0]);
      const closingTag = /<\/a>/i.exec(match[0]);

      if (!openingTag || !closingTag || openingTag.index > closingTag.index) {
        return true; // Invalid <a> tag found
      }
    }

    return false; // No invalid <a> tags found
  }


  isValidURL(url: string): boolean {

    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return pattern.test(url);
  }

  countInnerHTML(html: string): number {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.innerText.length; // count of characters in the inner text
  }

  mentionify(text) {
    const mentionRegex = /@(\w+)/g;
    return text.replace(mentionRegex, (match, username) => this.showprofileat(username));
  }

  showprofileat(social_tag) {
    const route = window?.location?.origin;
    const a = `${route}/${social_tag}`
    return `<a href="${a}">@${social_tag}</a>`
  }

  goToProfile(social_tag) {
    this.router.navigateByUrl(`/${social_tag}`);

  }

  updateObjectById(array, idToFind, updatedValues, userId, creatorId) {
    const index = array.findIndex(obj => obj.text.id === idToFind);

    if (index !== -1) {
      if (userId == creatorId) {
        array[index].text.comments_count = updatedValues.comments_count;
        array[index].text.likes_count = updatedValues.likes_count;
        array[index].text.dis_likes_count = updatedValues.dis_likes_count;
        array[index].text.user_likes_count = updatedValues.user_likes_count;
        array[index].text.user_dis_likes_count = updatedValues.user_dis_likes_count;
        array[index].text.user_comments_likes_count = updatedValues.user_comments_likes_count;
      } else {
        array[index].text.comments_count = updatedValues.comments_count;
        array[index].text.likes_count = updatedValues.likes_count;
        array[index].text.dis_likes_count = updatedValues.dis_likes_count;
      }
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }


  updateObject(array, new_data, userId, creatorId) {

    if (userId == creatorId) {
      array.comments_count = new_data.comments_count;
      array.likes_count = new_data.likes_count;
      array.dis_likes_count = new_data.dis_likes_count;
      array.user_likes_count = new_data.user_likes_count;
      array.user_dis_likes_count = new_data.user_dis_likes_count;
      array.user_comments_likes_count = new_data.user_comments_likes_count;
    } else {
      array.comments_count = new_data.comments_count;
      array.likes_count = new_data.likes_count;
      array.dis_likes_count = new_data.dis_likes_count;
    }
    return array;
  }

  deleteObjectById(array, idToFind) {
    console.log(array);
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.text.id === idToFind);

    // Check if an object with the specified Id was found
    // console.log(idToFind)
    if (index !== -1) {

      // Update the object with the new values
      array.splice(index, 1);
      // console.log(array);
    } else {
      // console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  deleteObjectByIdComment(array, idToFind) {
    console.log(array);
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.id === idToFind);

    // Check if an object with the specified Id was found
    // console.log(idToFind)
    if (index !== -1) {

      // Update the object with the new values
      array.splice(index, 1);
      // console.log(array);
    } else {
      // console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  updateSingleObjectById(array, idToFind) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.text.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      let updatedValues = {};
      // Define the updated values within the function
      if (array[index]['text']['user_likes_count'] < 1) {
        updatedValues = {
          likes_count: array[index]['text']['likes_count'] + 1,
          user_likes_count: array[index]['text']['user_likes_count'] + 1,
          dis_likes_count: array[index]['text']['user_dis_likes_count'] >= 1 ? array[index]['text']['dis_likes_count'] - 1 : array[index]['text']['dis_likes_count'],
          user_dis_likes_count: array[index]['text']['user_dis_likes_count'] >= 1 ? array[index]['text']['user_dis_likes_count'] - 1 : 0,

          // Add more key-value pairs as needed
        };
        array[index].text = { ...array[index].text, ...updatedValues };
      } else {
        updatedValues = {
          likes_count: array[index]['text']['likes_count'] - 1,
          user_likes_count: array[index]['text']['user_likes_count'] - 1,
        }
        array[index].text = { ...array[index].text, ...updatedValues };
      }

      // Update the object with the new values
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  updateSingleObjectByIdDislike(array, idToFind) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.text.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      let updatedValues = {};
      // Define the updated values within the function
      if (array[index]['text']['user_dis_likes_count'] < 1) {
        updatedValues = {
          dis_likes_count: array[index]['text']['dis_likes_count'] + 1,
          user_dis_likes_count: array[index]['text']['user_dis_likes_count'] + 1,
          likes_count: array[index]['text']['user_likes_count'] >= 1 ? array[index]['text']['likes_count'] - 1 : array[index]['text']['likes_count'],
          user_likes_count: array[index]['text']['user_likes_count'] >= 1 ? array[index]['text']['user_likes_count'] - 1 : 0,
          // Add more key-value pairs as needed
        };
        array[index].text = { ...array[index].text, ...updatedValues };
      } else {
        updatedValues = {
          dis_likes_count: array[index]['text']['dis_likes_count'] - 1,
          user_dis_likes_count: array[index]['text']['user_dis_likes_count'] - 1,
        }
        array[index].text = { ...array[index].text, ...updatedValues };
      }

      // Update the object with the new values
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  processFile(imageInput: any) {
    this.imageErrValue = "";
    this.imageError = false;
    this.qqq = true;
    // this.cdr.detach();
    const file: File = imageInput.files[0];

    if (file && file.size <= this.maxFileSize) {
      this.imageError = false;
      const reader = new FileReader();

      reader.addEventListener('load', (event: any) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          this.selectedFile = new ImageSnippet(event.target.result, file);
          this.imgFile = event.target.result;
          this.imageError = false;
          // this.cdr.detectChanges();
        };
      });

      reader.readAsDataURL(file);
    } else {
      this.imageError = true;
      this.imageErrValue = "File size should not exceed 3mb"
      this.toastService.error(this.imageErrValue);
      this.imgFile = null;
    }

    // this.cdr.reattach();
  }

  likePostUI(id, type = null) {
    if (type == null) {
      this.updateSingleObjectById(this.activityLogs, id)
    } else if (type == 'comment' || type == 'reply') {
      let updatedValues;
      if (this.post.user_likes_count < 1) {

        let myObject = this.post;
        updatedValues = {
          likes_count: this.post.likes_count + 1,
          user_likes_count: this.post.user_likes_count + 1,
          dis_likes_count: this.post.user_dis_likes_count >= 1 ? this.post.dis_likes_count - 1 : this.post.dis_likes_count,
          user_dis_likes_count: this.post.user_dis_likes_count >= 1 ? this.post.user_dis_likes_count - 1 : 0,
        }
        const updatedObject = { ...myObject, ...updatedValues };
        this.post = updatedObject;

      } else if (this.post.user_likes_count > 0) {
        let myObject = this.post;
        updatedValues = {
          likes_count: this.post.likes_count - 1,
          user_likes_count: this.post.user_likes_count - 1,
        }
        const updatedObject = { ...myObject, ...updatedValues };
        this.post = updatedObject;
      }

    }
    if (type == 'reply') {
      this.likeComment(id, id).subscribe({
        next: (res: any) => {
        },
        error: (err: any) => {
          console.log(err)
        },
        complete: () => {
          // this.showpage = true
        }
      })
    } else {
      this.likePost(id).subscribe({
        next: (res: any) => {
        },
        error: (err: any) => {
        },
        complete: () => {
          // this.showpage = true
        }
      })
    }

  }


  commentPost(content, item) {
    this.postDetails = item;
    this.modal.open(content, { centered: true, size: 'md', backdrop: true });
  }

  deletePostUI(post) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this post?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deletePost(post).subscribe({
          next: (res: any) => {
            if (res.success === true) {
              this.deleteObjectById(this.activityLogs, post.id)
            }
          },
          error: (err: any) => {
            //console.log(err);
            // this.alert.danger(err.error.message);
            // this.modalService.dismissAll();
          },
        });
      } else {
        // User clicked "No" or closed the dialog
      }
    });
  }

  // openImagePopup(url) {
  //   this.popupDataService.openImagePopup(url);
  // }
  deleteCommentUI(post) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this comment?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteComment(post).subscribe({
          next: (res: any) => {
            if (res.success === true) {
              this.deleteObjectByIdComment(this.comments, post.id)
              // this.getPostComments(this.route.snapshot.paramMap.get('post_id'))
            }
          },
          error: (err: any) => {
            //console.log(err);
            // this.alert.danger(err.error.message);
            // this.modalService.dismissAll();
          },
        });
      } else {
        // User clicked "No" or closed the dialog
      }
    });
  }

  openFileExplorer() {
    document.getElementById("file").click();
  }
  dislikePostUI(id, type = null, post_id = null) {
    if (type == null) {
      this.updateSingleObjectByIdDislike(this.activityLogs, id)
    } else if (type == 'comment' || type == 'reply') {
      let updatedValues;
      if (this.post.user_dis_likes_count < 1) {

        let myObject = this.post;
        updatedValues = {
          dis_likes_count: this.post.dis_likes_count + 1,
          user_dis_likes_count: this.post.user_dis_likes_count + 1,
          likes_count: this.post.user_likes_count >= 1 ? this.post.likes_count - 1 : this.post.likes_count,
          user_likes_count: this.post.user_likes_count >= 1 ? this.post.user_likes_count - 1 : 0,
        }
        const updatedObject = { ...myObject, ...updatedValues };
        this.post = updatedObject;

      } else if (this.post.user_dis_likes_count > 0) {
        let myObject = this.post;
        updatedValues = {
          dis_likes_count: this.post.dis_likes_count - 1,
          user_dis_likes_count: this.post.user_dis_likes_count - 1,
        }
        const updatedObject = { ...myObject, ...updatedValues };
        this.post = updatedObject;
      }
    }
    if (type == 'reply') {
      this.dislikeComment(id, id).subscribe({
        next: (res: any) => {
        },
        error: (err: any) => {
          console.log(err)
        },
        complete: () => {
          // this.showpage = true
        }
      })
    } else {
      this.dislikePost(id).subscribe({
        next: (res: any) => {
        },
        error: (err: any) => {
        },
        complete: () => {
          // this.showpage = true
        }
      })
    }

  }

  dislikeCommentUI(id, post_id, replies = false) {
    this.updateSingleObjectByIdDislikeComment(this.comments, id)

    this.dislikeComment(post_id, id).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        // this.showpage = true
      }
    })
  }

  likeCommentUI(id, post_id, replies = false) {
    this.updateSingleObjectByIdComment(this.comments, id)

    this.likeComment(post_id, id).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
        console.log(err)
      },
      complete: () => {
        // this.showpage = true
      }
    })
  }

  // dislikePost(id) {
  //   let updatedValues;
  //   if (this.feeds.user_dis_likes_count < 1) {

  //     let myObject = this.feeds;
  //     updatedValues = {
  //       dis_likes_count: this.feeds.dis_likes_count + 1,
  //       user_dis_likes_count: this.feeds.user_dis_likes_count + 1,
  //       likes_count: this.feeds.user_likes_count >= 1 ? this.feeds.likes_count - 1 : this.feeds.likes_count,
  //       user_likes_count: this.feeds.user_likes_count >= 1 ? this.feeds.user_likes_count - 1 : 0,
  //     }
  //     const updatedObject = { ...myObject, ...updatedValues };
  //     this.feeds = updatedObject;

  //   } else if (this.feeds.user_dis_likes_count > 0) {
  //     let myObject = this.feeds;
  //     updatedValues = {
  //       dis_likes_count: this.feeds.dis_likes_count - 1,
  //       user_dis_likes_count: this.feeds.user_dis_likes_count - 1,
  //     }
  //     const updatedObject = { ...myObject, ...updatedValues };
  //     this.feeds = updatedObject;
  //   }
  //   this.app.dislikePost(id).subscribe({
  //     next: (res: any) => {
  //     },
  //     error: (err: any) => {
  //       console.log(err)
  //     },
  //     complete: () => {
  //       // this.showpage = true
  //     }
  //   })
  // }

  commentOnPost(post_id, type = null) {
    let comment: any = {
      comment: "",
      post_id: ""
    };
    comment.post_id = post_id
    if (type == null) {
      comment.comment = this.comment.comment;
    } else if (type == 'tea2') {
      comment.comment = this.comment.tea2;
    } else if (type == 'tea') {
      comment.comment = this.comment.tea;
    }

    if (comment.comment !== "") {
      this.commenting = true;
      if (this.selectedFile === undefined || this.selectedFile === null) {
        this.test = this.imgFile;
      } else {
        this.test = this.selectedFile.file;
      }

      const pic = this.test;
      comment.file = pic;
      this.postComment(comment).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            this.commenting = false;
            this.comment.comment = "";
            this.comment.tea = "";
            this.comment.tea2 = "";
            this.imgFile = "";
            this.comment.file = "";
            this.test = "";
            this.comment.post_id = "";
            this.showEmojiPicker = false;
            this.modal.dismissAll();
            this.selectedFile = null;
            // this.allActivtyLogs(null)
            // this.getPostComments(this.route.snapshot.paramMap.get('post_id'));
            // this.alert.success(res.message);
          }
        },
        error: (err: any) => {
          this.commenting = false;
          // this.alert.danger(err.error.message);
          // this.modalService.dismissAll();
        },
      });
    }
  }

  closeModal() {
    this.showEmojiPicker = false;
    this.comment.comment = "";
    this.comment.tea = "";
    this.comment.tea2 = "";
    this.modal.dismissAll();
  }

  addEmoji(event, type = null) {
    if (type == null) {
      const text = `${this.comment.comment}${event.emoji.native}`;
      this.comment.comment = text;
    } else if (type == 'tea2') {
      const text = `${this.comment.tea2}${event.emoji.native}`;
      this.comment.tea2 = text;
    } else if (type == 'tea') {
      const text = `${this.comment.tea}${event.emoji.native}`;
      this.comment.tea = text;
    }


    // this.showEmojiPicker = false;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  updateSingleObjectByIdComment(array, idToFind) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      let updatedValues = {};
      // Define the updated values within the function
      if (array[index]['user_likes_count'] < 1) {
        updatedValues = {
          likes_count: array[index]['likes_count'] + 1,
          user_likes_count: array[index]['user_likes_count'] + 1,
          dis_likes_count: array[index]['user_dis_likes_count'] >= 1 ? array[index]['dis_likes_count'] - 1 : array[index]['dis_likes_count'],
          user_dis_likes_count: array[index]['user_dis_likes_count'] >= 1 ? array[index]['user_dis_likes_count'] - 1 : 0,
          // Add more key-value pairs as needed
        };
        array[index] = { ...array[index], ...updatedValues };
      } else {
        updatedValues = {
          likes_count: array[index]['likes_count'] - 1,
          user_likes_count: array[index]['user_likes_count'] - 1,
        }
        array[index] = { ...array[index], ...updatedValues };
      }

      // Update the object with the new values
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  updateSingleObjectByIdDislikeComment(array, idToFind) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      let updatedValues = {};
      // Define the updated values within the function
      if (array[index]['user_dis_likes_count'] < 1) {
        updatedValues = {
          dis_likes_count: array[index]['dis_likes_count'] + 1,
          user_dis_likes_count: array[index]['user_dis_likes_count'] + 1,
          likes_count: array[index]['user_likes_count'] >= 1 ? array[index]['likes_count'] - 1 : array[index]['likes_count'],
          user_likes_count: array[index]['user_likes_count'] >= 1 ? array[index]['user_likes_count'] - 1 : 0,
          // Add more key-value pairs as needed
        };
        array[index] = { ...array[index], ...updatedValues };
      } else {
        updatedValues = {
          dis_likes_count: array[index]['dis_likes_count'] - 1,
          user_dis_likes_count: array[index]['user_dis_likes_count'] - 1,
        }
        array[index] = { ...array[index], ...updatedValues };
      }

      // Update the object with the new values
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }

  updateObjectByIdComment(array, idToFind, updatedValues, userId, creatorId) {
    // Find the index of the object with the specified Id
    const index = array.findIndex(obj => obj.id === idToFind);

    // Check if an object with the specified Id was found
    if (index !== -1) {
      // Update the object with the new values
      // console.log(array)
      // console.log(updatedValues)
      // // array[index] = { ...array[index], ...updatedValues };
      // console.log(updatedValues?.replies_count);
      if (userId == creatorId) {
        array[index].comments_count = (updatedValues?.replies_count || updatedValues?.comments_count) ?? 0;
        array[index].likes_count = updatedValues.likes_count;
        array[index].dis_likes_count = updatedValues.dis_likes_count;
        array[index].user_likes_count = updatedValues.user_likes_count;
        array[index].user_dis_likes_count = updatedValues.user_dis_likes_count;
        array[index].user_comments_likes_count = updatedValues.user_comments_likes_count;
      } else {
        array[index].comments_count = (updatedValues?.replies_count || updatedValues?.comments_count) ?? 0;
        array[index].likes_count = updatedValues.likes_count;
        array[index].dis_likes_count = updatedValues.dis_likes_count;
      }
      console.log(array[index]);
    } else {
      console.log(`Object with Id ${idToFind} not found.`);
    }

    return array;
  }
  payPerView(id) {
    return this.http.get(`${environment.base_api}/user/medias/posts/subscribe-to-post/${id}`).pipe()
  }


  linkTwitter(): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${api}/user/link-twitter-v2`).pipe(
      map((rs) => {
        return rs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  getPriceInGary(price) {
    // this.getGaryBalance()
    return price / this.garyPrice;
  }
  getWenAirdropDetails() {
    return this.http.get(`${api}/solana/jup-balance`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }
  claimWenAirdrop() {
    return this.http.get(`${api}/solana/claim-jup`, httpOptions)
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  checkClaimStatus(reference) {
    const auth = this.getAuthFromLocalStorage();
    const token = auth.data.token
    const httpOp = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
    };
    return this.http.get(`${environment.base_api}/solana/get-reference/${reference}`,).pipe()
  }

  getPoolStats() {
    return this.http.get(`${stake_api}/getPoolStat?stake_key=${stake_key}&program=${program}`).pipe()
  }
  getPool() {
    return this.http.get(`${stake_api}/getPool?stake_key=${stake_key}&program=${program}&nonce=${nonce}`).pipe()
  }
  getActiveStake(custodial_wallet) {
    return this.http.get(`${stake_api}/getActiveStakes?stake_key=${stake_key}&program=${program}&owner=${custodial_wallet}`).pipe()
  }

  depositStake(data) {
    return this.http.post(`${stake_api}/depositRaw`, data).pipe()
  }

  claimStake(data) {
    return this.http.post(`${stake_api}/claimRaw`, data).pipe()
  }

  withdrawStake(data) {
    return this.http.post(`${stake_api}/withdrawRaw`, data).pipe()
  }

  checkConfig() {
    return this.http.get(`https://gateway.socialconnector.io/api/v2/exchange/wormholeconfig`).pipe()
  }

  getPriority() {
    const a = localStorage.getItem(this.priority_key)
    if (!a) {
      localStorage.setItem(this.priority_key, this.priority_fee.toString())
    } else {
      this.priority_fee = parseFloat(a)
    }
    console.log('priority', this.priority_fee)
  }

  claimReward(data) {
    return this.http.post(`${environment.base_api}/solana/claim-reward`, data).pipe()
  }

  claimRewardCustodial(data) {
    return this.http.post(`${environment.base_api}/solana/claim-reward-custodial`, data).pipe()
  }
}



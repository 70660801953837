<main>
  <div class="text-center p-2 color" *ngIf="exchange_message">
    <p class="mb-0 fw-bold"><i class="fa-regular fa-circle-info"></i> {{ exchange_message }} </p>
  </div>
  <!-- <ngx-loading-bar ref="http" color="#ffffff" height="5px" diameter="20px"></ngx-loading-bar> -->

  <nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="!url.includes('landing')">
    <div class="container-fluid mx-md-5">
      <a class="navbar-brand d-none d-sm-block" [href]="'/'">
        <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
        <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" />
      </a>
      <!-- <div>
        <div class="row connect py-3 px-2 d-flex d-lg-none">
          <div class="col-4 border-end border-right">
            <p class="fs-7">$GARY</p>
            <h4>{{ priceRsc?.gary_balance || 0 | number : '1.0-2' }}</h4>
          </div>
          <div class="col-4 border-end border-right">
            <p class="fs-7">SOL</p>
            <h4>{{ priceRsc?.sol_balance || 0 | number :
              '1.0-2' }}</h4>
          </div>
          <div class="col-4">
            <p class="fs-7">Portfolio Value</p>
            <h4>$ {{ totalBalance || 0 | number : "1.0-2"}} </h4>
          </div>
        </div>
      </div> -->
      <a class="navbar-brand d-block d-sm-none">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </a>
      <div class="text-center">
        <a class="navbar-brand d-block d-sm-none" [href]="'/home'">
          <img src="assets/imgs/garylogo.png" width="50" alt="" *ngIf="theme == 'light'" />
          <img src="assets/imgs/garylogowhite.png" width="50" alt="" *ngIf="theme != 'light'" />
        </a>
      </div>
      <button class="navbar-toggler text-white" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
        <!-- <span class="navbar-toggler-icon text-white"></span> -->
        <i class="fa-solid fa-bars text-white"></i>
      </button>

      <!-- Step 2: Add the ngbCollapse directive to the element below. -->
      <div [ngbCollapse]="isMenuCollapsed" class="nav-content collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-center">
          <!-- <li class="nav-item">

            <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" (click)="isMenuCollapsed = true">Home
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/post']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Feed</a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/members']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Members</a>
          </li> -->

          <!-- <li class="nav-item">

            <a class="nav-link" [routerLink]="['/top_100']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Top 100</a>

          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/tokens']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Communities
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/events']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Events</a>
          </li> -->

          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/tower']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Towers</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/lock_gary']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">$GARY Locked</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/rewards']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Rewards</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/rewards-custodial']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Rewards Custodial</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://app.realms.today/dao/BiLhzxXqUX1ckdQGjZRNSd3Fha36XwVJhJFS4x1NePeR" (click)="isMenuCollapsed = true">
              Voting</a>
          </li>

          <!-- <li class="nav-item d-block d-md-none">
            <a class="nav-link" [href]="'/' +users?.social_tag " routerLinkActive="active"
              (click)="isMenuCollapsed = true">My Profile</a>
          </li> -->





        </ul>
        <!-- <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center" *ngIf="showsearch"></ul>  -->

        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center" *ngIf="!showsearch">
          <li class="nav-item" *ngIf="garyPrice">
            <a class="nav-link"
              href="https://birdeye.so/token/8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk?chain=solana" target="_blank">
              {{ coin_symbol }}: ${{ garyPrice|number : '1.0-4' }} </a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link active22" [routerLink]="['/get_involved']" routerLinkActive="active"
              (click)="isMenuCollapsed = true">Get Involved
            </a> -->
            <a class="nav-link active22" href="https://t.me/garyclub"
             >Get Involved
            </a>
          </li>
          <!-- <li class="nav-item d-block d-md-none">
            <div class="text-cnter">
              <div *ngIf="theme !== 'dark-theme'">
                <a class="nav-link notification_btn" [href]="['/notifications']">
                  Notifications
                  <span class="notification_badge" *ngIf="hasNotification"></span>
                </a>
              </div>
              <div *ngIf="theme === 'dark-theme'">
                <a class="nav-link notification_btn" [href]="['/notifications']">
                  Notifications
                  <span class="notification_badge" *ngIf="hasNotification"></span>
                </a>
              </div>
            </div>
          </li> -->
          <li class="nav-item d-block d-md-none">
            <a class="nav-link" href="https://app.socialconnector.io" (click)="isMenuCollapsed = true">Social
              Connector</a>
          </li>
          <li class="nav-item d-block d-md-none">
            <a class="nav-link" *ngIf="isAuthenticated" (click)="logOut()" style="cursor: pointer;">Log Out</a>
          </li>

          <!-- <li class="nav-item">
            <a class="nav-link" *ngIf="!isAuthenticated" (click)="loginModal(content)"
              style="cursor: pointer;">Login</a>
          </li> -->

          <!-- <li class="nav-item">
            <button class="btn btn-icon btn-clear" ngbTooltip="Search for a creator" (click)="search(content2)">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </li> -->

          <li class="nav-item">
            <div class="text-cnter">
              <div *ngIf="theme !== 'dark-theme'">
                <button class="btn btn-clear" (click)="toggleDarkTheme()">
                  <i class="fa-solid fa-moon text-dark"></i>
                </button>
              </div>
              <div *ngIf="theme === 'dark-theme'">
                <button class="btn btn-clear" (click)="toggleDarkTheme()">
                  <i class="fa-solid fa-sun text-light"></i>
                </button>
              </div>
            </div>
          </li>

          <!-- <li class="nav-item d-none d-lg-block">
            <div class="text-cnter">
              <div *ngIf="theme !== 'dark-theme'">
                <a class="btn btn-clear" [href]="['/'+users?.social_tag]">
                  <i class="fa-solid fa-user text-dark"></i>
                </a>
              </div>
              <div *ngIf="theme === 'dark-theme'">
                <a class="btn btn-clear" [href]="['/'+users?.social_tag]">
                  <i class="fa-solid fa-user text-light"></i>
                </a>
              </div>
            </div>
          </li> -->



          <!-- <li class="nav-item d-none d-md-block">
            <div class="text-cnter">
              <div *ngIf="theme !== 'dark-theme'">
                <a class="btn btn-clear notification_btn" [href]="['/notifications']">
                  <i class="fa-solid fa-bell text-dark notification_icon"></i>
                  <span class="notification_badge" *ngIf="hasNotification"></span>
                </a>
              </div>
              <div *ngIf="theme === 'dark-theme'">
                <a class="btn btn-clear notification_btn" [href]="['/notifications']">
                  <i class="fa-solid fa-bell text-light notification_icon"></i>
                  <span class="notification_badge" *ngIf="hasNotification"></span>
                </a>
              </div>
            </div>
          </li> -->
          <li class="nav-item d-none d-md-block">
            <!-- <button class="btn btn-clear" (click)="toggleRPC()">
              <i class="fa-duotone fa-sliders"></i>
            </button> -->

            <div ngbDropdown class="d-inline-block" #myDrop="ngbDropdown">
              <button type="button" class="btn btn-clear" id="dropdownBasic1" ngbDropdownToggle>
                <i class="fa-duotone fa-sliders"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div class="p-2">
                  <h4>Priority Fee</h4>
                  <div class="btn-group">
                    <button type="button" class="btn " [class]="priority === 'default' ? 'btn-clear': ''"
                      (click)="setRPC('default')">Default</button>
                    <button type="button" class="btn " [class]="priority === 'fast' ? 'btn-clear': ''"
                      (click)="setRPC('fast')">Fast</button>
                    <button type="button" class="btn " [class]="priority === 'turbo' ? 'btn-clear': ''"
                      (click)="setRPC('turbo')">Turbo</button>
                    <button type="button" class="btn " [class]="priority === 'custom' ? 'btn-clear': ''">Custom</button>
                  </div>
                  <div class="form-group">
                    <input type="number" class="form-control" [(ngModel)]="priority_fee"
                      (ngModelChange)="setpriority()">
                  </div>
                  <div class="text-end mt-2">
                    <button class="btn btn-custom" (click)="save();  myDrop.close()">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <div class="nav-item ms-5 d-none d-lg-flex" *ngIf="totalBalance != null">
            <div class="row connect  d-none d-lg-flex" (click)="navigatetodashboard()">
              <div class="col-4 border-end border-right">
                <p class="fs-7">$GARY</p>
                <h4 class="mb-0">{{ priceRsc?.gary_balance || 0 | number : '1.0-2' }}</h4>
                <!-- <h4>999,999.99 </h4> -->
              </div>
              <div class="col-4 border-end border-right">
                <p class="fs-7">SOL</p>
                <h4 class="mb-0">{{ priceRsc?.sol_balance || 0 | number :'1.0-2' }}</h4>
                <!-- <h4>999,999.99 </h4> -->
              </div>
              <div class="col-4">
                <p class="fs-7">Portfolio Value</p>
                <h4 class="mb-0">${{ totalBalance || 0 | number : "1.0-2"}} </h4>
                <!-- <h4>$999,999.99 </h4> -->
              </div>
            </div>
            <!-- <button class="btn btn-connect">
              <span *ngIf="priceRsc">
                {{ priceRsc?.gary_balance | number : '1.0-2' }} {{ coin_symbol }} | {{ priceRsc?.sol_balance | number :
                '1.0-2' }} SOL | {{ totalBalance || 0 | number : "1.0-2"}}
              </span>
              <span *ngIf="!priceRsc">
                Loading <i class="fa-solid fa-ellipsis fa-fade"></i>
              </span>

            </button> -->



            <!-- <div class="text-center">
              <div ngbDropdown class="d-inline-block" *ngIf="(connected$ | async) === false">
                <button type="button" class="btn btn-connect py-3 px-5" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="fa-solid fa-wallet"></i> &nbsp;&nbsp; Connect Wallet
                </button>
                <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu3">

                  <li *ngFor="let wallet of wallets$ | async">
                    <button ngbDropdownItem class="dropdown-item2 d-flex align-items-center"
                      (click)="onSelectWallet(wallet.adapter.name)">
                      <span>
                        <img [src]="fixUnsafe(wallet.adapter.icon)" width="20" height="20" class="me-3"
                          alt="" /></span>{{ wallet.adapter.name }}

                    </button>
                  </li>
                </ul>
              </div>

              <div ngbDropdown class="d-inline-block" *ngIf="connected$ | async">
                <button type="button" class="btn btn-gradient py-2 px-5 text-end" id="dropdownBasic2" ngbDropdownToggle>
                  <span>
                    <img [src]="fixUnsafe(walletIcon$ | async)" width="20" height="20" class="me-3" alt="" /></span>
                  {{ truncate(publicKey$ | async) }} <br />
                  <span class="text-small text-end">{{
                    walletName$ | async
                    }}</span>
                </button>
                <ul ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu3">

                  <li>
                    <button ngbDropdownItem class="dropdown-item2 d-flex align-items-center" (click)="copyText()"
                      *ngIf="connected$ | async">
                      <i class="fa-solid fa-copy"></i> &nbsp;&nbsp; Copy Address
                    </button>
                  </li>
                  <li>
                    <button ngbDropdownItem class="dropdown-item2 d-flex align-items-center" (click)="onDisconnect()"
                      *ngIf="connected$ | async">
                      <i class="fa-solid fa-circle-xmark"></i>&nbsp;&nbsp;
                      Disconnect
                    </button>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </ul>
      </div>
    </div>
  </nav>

  <div class="mb-5">
    <router-outlet></router-outlet>
  </div>
</main>

<div class="footer py-3" *ngIf="!url.includes('landing')">
  <div class="container-fluid">
    <div class="row align-items-center d-none d-md-flex">
      <div class="col-md-2">
        <img src="assets/imgs/gary_logo_white.png" alt="" />
      </div>
      <div class="col-md-4">
        <h6>
          Copyright <span class="text-muted">&copy;</span>
          {{ year }} Gary Club
        </h6>
      </div>

      <div class="col-md-6 text-md-end">
        <div class="">
          <a [routerLink]="['/swap']" routerLinkActive="active" class="footer-link">Exchange
          </a>

          <a href="https://app.socialconnector.io" class="footer-link">Social Connector
          </a>
          <!-- <a href="https://discord.gg/garyclub" class="footer-link" target="_blank" class="me-3">Discord</a> -->

          <a *ngIf="isAuthenticated" style="cursor: pointer" (click)="logOut()" class="footer-link">Log Out</a>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <h6>Enter your Social Connector details to login</h6>
    <form [formGroup]="joinForm">
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" name="email" class="form-control" formControlName="email"
          placeholder="Enter Social Connector Email" />
      </div>
      <div class="form-group">
        <label for="" class="form-label">Password
          &nbsp; <span><a href="https://app.socialconnector.io/auth/forgot-password">Forgot Password?</a></span>
        </label>
        <input type="password" name="password" class="form-control" formControlName="password"
          placeholder="Enter Social Connector Password" />
      </div>
    </form>

    <p class="pt-3" style="float:right;">
      New here?
      <a href="https://app.socialconnector.io/auth/registration" target="_blank">Create a FREE Account</a>
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-clear text-danger" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" (click)="login()" [disabled]="joinForm.invalid">
      Login
    </button>
  </div>
</ng-template>


<ng-template #content2 let-modal>

  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Search for a Creator</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div> -->

  <div class="modal-body ">
    <form class="DocSearch-Form">
      <label class="DocSearch-MagnifierLabel" for="docsearch-input" id="docsearch-label"><svg width="20" height="20"
          class="DocSearch-Search-Icon" viewBox="0 0 20 20">
          <path
            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
            stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg></label>
      <div class="DocSearch-LoadingIndicator">
        <svg viewBox="0 0 38 38" stroke="currentColor" stroke-opacity=".5">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".3" cx="18" cy="18" r="18"></circle>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                  repeatCount="indefinite"></animateTransform>
              </path>
            </g>
          </g>
        </svg>
      </div>
      <input class="DocSearch-Input" aria-autocomplete="both" aria-labelledby="docsearch-label" id="docsearch-input"
        autocomplete="off" autocorrect="off" autocapitalize="none" enterkeyhint="search" spellcheck="false"
        autofocus="true" placeholder="Search for a creator" maxlength="64" type="search" /><button type="reset"
        title="Clear the query" class="DocSearch-Reset" aria-label="Clear the query" hidden="">
        <svg width="20" height="20" viewBox="0 0 20 20">
          <path d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z" stroke="currentColor"
            fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </button>
    </form>

  </div>
</ng-template>

<!-- <ng-template #content3 let-modal></ng-template> -->

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppServiceService } from '../_services/app-service.service';
import { Router } from '@angular/router';
import { NgbAccordionConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { InjectionToken } from '@angular/core';
import { WalletService } from '../_services/wallet.service';
import { WalletName } from '@solana/wallet-adapter-base';
import { getAssociatedTokenAddress } from 'src/utils';
import { PublicKey, Transaction, VersionedTransaction, ComputeBudgetProgram } from '@solana/web3.js';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
export const INFINITE_LIST = new InjectionToken<InfiniteList>('INFINITE_LIST');

export function provideInfiniteList(initialData: number[]) {
  return new InfiniteList(initialData);
}

export const InfiniteListProvider = {
  provide: INFINITE_LIST,
  useFactory: provideInfiniteList,
  deps: []
};

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards-custodial.component.html',
  styleUrls: ['./rewards-custodial.component.scss']
})
export class RewardsCustodialComponent implements OnInit, OnDestroy {
  rewards: any;
  dater: boolean = false;
  loading: boolean = false;
  private countdownInterval: any;
  end_dater: boolean;
  connection = this.walletservice.connection;
  sBalance: any;
  walletvalue: any = 'sc';

  readonly wallets$ = this.walletservice.wallets$;
  readonly wallet$ = this.walletservice.wallet$;
  readonly walletName$ = this.walletservice.walletName$;
  readonly walletIcon$ = this.walletservice.walletIcon$;
  readonly ready$ = this.walletservice.ready$;

  readonly connected$ = this.walletservice.connected$;
  public publicKey$ = this.walletservice.publicKey$;
  coin_symbol: any;
  garyPrice: any;
  rlyPrice: any;
  exchangemessage: any = null
  coinprice: any;
  coinprices: any;
  priority_fee: number;
  showLoad: boolean;
  custodial_wallet: any;
  signature: any;
  transaction: any;
  isAuthenticated: boolean;

  constructor(
    config: NgbAccordionConfig,
    private app: AppServiceService,
    private router: Router,
    private orderPipe: OrderPipe,
    private modalService: NgbModal,
    private wallet: WalletService,
    public walletservice: WalletService,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit(): Promise<void> {
    const auth = this.app.getAuth()
    console.log(auth)
    if (auth) {
      this.isAuthenticated = true
    } else {
      this.isAuthenticated = false
      this.router.navigate(['/login'])
    }
    const a = await this.app.getUser()
    this.custodial_wallet = a?.custodial_wallet

    await this.checkConnection();
    if(this.custodial_wallet){
    this.getAllRewards(this.custodial_wallet);
    }else{
      this.getAllRewards("9vroVqNF8DNfpGtoy6fPB9Up7V4iD2sAimEowgEPFzf5");
    }
  }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  claimReward(data) {
    console.log(data.proof);
    this.loading = true;
    let payload = {
      'merkle_tree': data.distributor,
      'token_address': data.mint,
      'amount': data.amount,
      'index': data.index,
      'proof': data.proof,
      'wallet_address': this.custodial_wallet
    };
    console.log(payload);
    this.app.claimRewardCustodial(payload).subscribe({
      next: (res: any) => {

        // this.loading = false;
        this.transaction = res.data.tx ?? null;
        console.log(this.transaction);
        // this.getAllRewards(this.custodial_wallet);
      },
      error: (error) => {
        alert(error.error.message);
        this.loading = false;
      },
      complete: () => {
        Swal.fire('Success', "Gary Reward Claimed Successful", 'success').then((_) => {
          window.location.reload()

        })
        this.loading = false;
      }
    });
  }



  getAllRewards(wallet: any) {
    this.loading = true;
    this.app.getallRewards(wallet).subscribe({
      next: (res: any) => {
        this.rewards = res.data;
        // console.log(this.rewards)
        this.initializeCountdown();
        const now = Date.now();
        console.log(now);
        if (this.rewards[0].config.start_date > now) {
          this.dater = true;
        } else {
          this.dater = false;
        }
        if (this.rewards[0].config.end_date < now) {
          this.end_dater = true;
        } else {
          this.end_dater = false;
        }
      },
      complete: () => {
        this.loading = false;
      }
    });
  }



  checkConnection() {

    const intervalId = setInterval(async () => {
      if (this.walletservice.connection !== undefined) {
        clearInterval(intervalId); // Clear the interval once the connection is established
        // console.log('Connection is established:', this.walletservice.connection);
        // You can do whatever you need to do after the connection is established here

        this.getAllRewards(this.custodial_wallet);

      }
    }, 800); // Check every 1 second
  }

  fixUnsafe(url: string) {
    //  let newurl =url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onDisconnect() {
    this.walletservice.onDisconnect();
    this.getAllRewards("9vroVqNF8DNfpGtoy6fPB9Up7V4iD2sAimEowgEPFzf5");
  }

  truncate(name: any) {
    const name2: string = JSON.stringify(name);
    const a = JSON.parse(name2);

    var truncateRegex =
      /^([1-9A-HJ-NP-Za-km-z]{6})[1-9A-HJ-NP-Za-km-z]+([1-9A-HJ-NP-Za-km-z]{4})$/;
    var match = a.match(truncateRegex);
    // console.log(match);
    if (!match) return name;
    return match[1] + '\u2026' + match[2];
  }

  initializeCountdown() {
    const reward = this.rewards[0];
    if (!this.end_dater) {
      this.countdownInterval = setInterval(() => {
        const now = Date.now();
        const startDate = reward.config.start_date;
        const endDate = reward.config.end_date;
        // console.log(startDate)
        // console.log(endDate)

        let countDownDate;
        let countdownLabel = '';

        // Before start date
        if (now < startDate) {
          countDownDate = startDate;
          countdownLabel = 'Time until start:';
        }
        // Between start and end date
        else if (now >= startDate && now < endDate) {
          countDownDate = endDate;
          countdownLabel = 'Time remaining:';
        }
        // After end date
        else {
          clearInterval(this.countdownInterval);
          document.getElementById("countdown").innerHTML = "EXPIRED";
          return;
        }

        const distance = countDownDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Update countdown label
        // document.getElementById("countdown-label").innerHTML = countdownLabel;
        
        // Update time values
        document.getElementById("days").innerHTML = days.toString();
        document.getElementById("hours").innerHTML = hours.toString();
        document.getElementById("minutes").innerHTML = minutes.toString();
        document.getElementById("seconds").innerHTML = seconds.toString();

        if (distance < 0) {
          clearInterval(this.countdownInterval);
          document.getElementById("countdown").innerHTML = "EXPIRED";
        }
      }, 1000);
    }
  }
}

class InfiniteList {
  private data: number[] = [];
  private index = 0;

  constructor(private initialData: number[]) {
    this.data = initialData;
  }

  public next() {
    if (this.index >= this.data.length) {
      this.index = 0;
    }
    return this.data[this.index++];
  }
}

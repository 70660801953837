import { GetInvolvedComponent } from './get-involved/get-involved.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SwapComponent } from './swap/swap.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TokenDetailsComponent } from './token-lists/token-details/token-details.component';
import { TokenListsComponent } from './token-lists/token-lists.component';
import { PrivateSwapComponent } from './private-swap/private-swap.component';
import { GaryComponent } from './gary/gary.component';
import { CreatorSwapComponent } from './creator-swap/creator-swap.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { GiraffeTowerComponent } from './giraffe-tower/giraffe-tower.component';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { EventDetailsComponent } from './event-calendar/event-details/event-details.component';
import { RsvpComponent } from './event-calendar/rsvp/rsvp.component';
import { CreatorDashboardComponent } from './creator-dashboard/creator-dashboard.component';
import { TowerComponent } from './tower/tower.component';
import { TowerMembersComponent } from './tower-members/tower-members.component';
import { ProfileComponent } from './profile/profile.component';
import { CreatorLeaderboardComponent } from './profile/creator-leaderboard/creator-leaderboard.component';
import { UserTansactionsComponent } from './profile/user-tansactions/user-tansactions.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { ActivitiesComponent } from './activities/activities.component';
import { ActivityComponent } from './activities/activity/activity.component';
import { FamComponent } from './activities/fam/fam.component';
import { SupporterComponent } from './activities/supporter/supporter.component';
import { SupportingComponent } from './activities/supporting/supporting.component';
import { NotificationComponent } from './notification/notification.component';
import { PostComponent } from './post/post.component';
import { CommentsComponent } from './post/comments/comments.component';
import { RepliesComponent } from './post/replies/replies.component';
import { PostProfileComponent } from './post/post-profile/post-profile.component';
import { MembersComponent } from './tower/members/members.component';
import { SCSwapComponent } from './swap/scswap/scswap.component';
import { NewSwapComponent } from './new-swap/new-swap.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { CompleteregComponent } from './auth/completereg/overview/completereg.component';
import { PowComponents } from './pow/pow.component';
import { WenAirdropComponent } from './wen-airdrop/wen-airdrop.component';
import { JupAirdropComponent } from './jup-airdrop/jup-airdrop.component';
import { GaryLockedComponent } from './gary-locked/gary-locked.component';
import { AllMembersComponent } from './members/members.component';
import { SclockComponent } from './gary-locked/sclock/sclock.component';
import { ExternallockComponent } from './gary-locked/externallock/externallock.component';
import { RewardsComponent } from './rewards/rewards.component';
import { RewardsCustodialComponent } from './rewards-custodial/rewards-custodial.component';
// import { HomeComponent } from './home/home.component';
// import { TestComponent } from './test/test.component';

export const routes: Routes = [
  // {
  //   path: '',
  //   component: AppComponent
  // },
  // {
  //   path: 'complete-registeration',
  //   component: CompleteregComponent
  // },

  { path: 'login', component: LoginComponent },
  { path: 'signup', component: RegisterComponent },
  { path: 'signup/:ref', component: RegisterComponent },
  { path: 'forgot_password', component: ForgotPasswordComponent },
  { path: 'reset_password', component: ResetPasswordComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  {
    path: '',
    // canActivate: [AuthGuard],
    component: HomeComponent,
    children: [
      // {
      //   path: 'home',
      //   component: ActivitiesComponent,
      //   children: [
      //     { path: 'activity', component: ActivityComponent },
      //     { path: 'fam', component: FamComponent },
      //     { path: 'supporter', component: SupporterComponent },
      //     { path: 'supporting', component: SupportingComponent },
      //     { path: '', redirectTo: 'activity', pathMatch: 'full' }
      //   ]
      // },
      // {
      //   path: 'members',
      //   component: AllMembersComponent,
      //   children: [
      //     { path: 'creator-leaderboard', component: CreatorLeaderboardComponent },
      //     { path: 'tower', component: TowerComponent },
      //     { path: 'top_100', component: LeaderboardComponent },
      //     { path: '', redirectTo: 'creator-leaderboard', pathMatch: 'full' }
      //   ]
      // },
      {
        path: 'tokens',
        component: TokenListsComponent
      },
      {
        path: 'rewards',
        component: RewardsComponent
      },
      {
        path: 'tokens/:token',
        component: TokenDetailsComponent
      },
      // {
      //   path: 'airdrop/jup',
      //   component: JupAirdropComponent
      // },
      {
        path: 'swap',
        component: NewSwapComponent,
        children: [
          {
            path: 'external_swap',
            component: SwapComponent
          },
          {
            path: 'sc_swap',
            canActivate: [AuthGuard],
            component: SCSwapComponent
          },
          {
            path: 'sc_swap/:ref',
            component: SCSwapComponent
          },
          {
            path: 'external_swap/:ref',
            component: SwapComponent
          },

          { path: '', redirectTo: 'external_swap', pathMatch: 'full' }

        ]
      },



      // {
      //   path: 'external_swap',
      //   component: SwapComponent
      // },
      // {
      //   path: 'sc_swap',
      //   component: SCSwapComponent
      // },
      // {
      //   path: 'gary',
      //   component: GaryComponent
      // },

      {
        path: 'notifications',
        component: NotificationComponent
      },

      {
        path: 'lock_gary', component: GaryLockedComponent,
        children: [
          { path: '', redirectTo: 'external_lock', pathMatch: 'full' },
          { path: 'sc_lock', canActivate: [AuthGuard], component: SclockComponent },
          { path: 'external_lock', component: ExternallockComponent },
        ]
      },
      // {
      //   path: 'tower-members/:ref',
      //   component: MembersComponent
      // },
      {
        path: 'private_swap',
        component: PrivateSwapComponent
      },
      { path: 'creator_swap', component: CreatorSwapComponent },
      { path: 'welcome', component: WelcomeComponent },
      { path: 'get_involved', component: GetInvolvedComponent },
      { path: 'giraffe_tower', component: GiraffeTowerComponent },
      // { path: 'pow', component: PowComponents },
      // { path: 'events', component: EventCalendarComponent },
      // { path: 'events/:id', component: EventDetailsComponent },
      // { path: 'events/:id/rsvp', component: RsvpComponent },

      // { path: 'landing/:coin_symbol', component: CreatorDashboardComponent },

      // { path: 'post', component: PostComponent },
      // { path: 'post/:post_id', component: CommentsComponent },
      // { path: 'posts/:social_tag', component: PostProfileComponent },
      // { path: 'replies/:comment_id', component: RepliesComponent },
      // { path: ':social_tag', component: ProfileComponent },
      { path: 'user/transactions', component: UserTansactionsComponent },

      { path: 'rewards-custodial', component: RewardsCustodialComponent },

      // { path: 'airdrop/wen', component: WenAirdropComponent },
      {
        path: '',
        redirectTo: 'swap',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'swap',
      }
    ]
  },

  // {
  //   path: '',
  //   redirectTo: 'tokens',
  //   pathMatch: 'full'
  // },
  {
    path: '**',
    redirectTo: 'home',
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
